<template>
	<div class="cardministration">
		<el-card class="box-card father-card" shadow="never">
			<!-- 头部区域 -->
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<!-- 搜索框 -->
					<el-form-item label="机构名称">
						<el-select v-model="form.companyName" placeholder="请选择机构" clearable>
							<el-option v-for="item in institutionsOptions" :key="item.uuid" :label="item.companyName"
								:value="item.uuid">
							</el-option>
						</el-select>
					</el-form-item>
                    <el-form-item label="状态">
						<el-select v-model="form.statusOfOnpost" placeholder="请选择状态" clearable>
							<el-option v-for="item in statusList" :key="item.status" :label="item.label"
								:value="item.status">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="驾驶员名称">
						<el-input v-model="form.driverName" placeholder="请输入驾驶员名称" clearable></el-input>
					</el-form-item>
					<el-form-item label="电话号码">
						<el-input v-model="form.phoneNo" placeholder="请输入电话号码" clearable></el-input>
					</el-form-item>
					<!-- 按钮区域 -->
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
                        <el-button v-permission="['/driverAdd']"  type="primary" icon="el-icon-plus" @click="addDialogFormVisible = true">添加
						</el-button>
    					<el-button type="primary" icon="el-icon-delete" @click="reset">重置</el-button>

                    </el-form-item>
				</el-form>
			</el-row>
			<!-- 表格区域 -->
			<el-row class="tab">
				<el-table ref="multipleTable" :data="tabData" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowClass">
					<el-table-column prop="driverName" label="驾驶员名称" align="center"></el-table-column>
					<!-- <el-table-column prop="workNo" label="工号" align="center"></el-table-column> -->
					<el-table-column prop="gender" label="性 别" align="center"></el-table-column>
					<el-table-column prop="idcNum" label="身份证号码" align="center"></el-table-column>
					<el-table-column prop="phoneNo" label="联系电话" align="center"></el-table-column>
					<el-table-column prop="personDivideRatio" label="分成比例" align="center"></el-table-column>
					<el-table-column prop="licenseId" label="机动车驾驶证号" align="center"></el-table-column>
                    <el-table-column prop="companyName" label="机构名称" align="center"></el-table-column>

					<el-table-column prop="emergencyContact" label="紧急联系人" align="center"></el-table-column>
					<el-table-column prop="emergencyContactPhone" label="紧急联系人电话" align="center"></el-table-column>
					<el-table-column prop="statusOfOnpost" label="状态" align="center">
						<template slot-scope="scope">
							<span effect="dark" type="success" v-if="scope.row.statusOfOnpost==='1'">{{ '在职' }}
							</span>
							<span type="danger" effect="dark" v-else>{{ '离职' }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center" min-width="200px">
						<template slot-scope="scope">
							<el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
							<el-button v-permission="['/driverUpd']" size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改
							</el-button>
							<el-button v-permission="['/driverDevide']" size="mini" type="primary" @click="personClick(scope.row)">分成配置
							</el-button>
							<el-button v-permission="['/driverDel']" size="mini" v-if="scope.row.statusOfOnpost!='1'" type="danger" icon="el-icon-delete-solid" @click="open(scope.row)">删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页区域 -->
				<el-pagination background @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.PageNo" :page-sizes="[10, 15, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
			</el-row>
			<!--详情弹出框 -->
			<el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog" @close="editDialogClose()">
				<span slot="title" class="dialog-Title">转运类型</span>
				<el-form :model="selectForm" inline ref="selectForm" :rules="selectFormRules" class="selectForm">
					<!-- 第一行 -->
					<el-row>
						<el-form-item label="驾驶员名称" prop="driverName">
							<el-input v-model="selectForm.driverName" autocomplete="off" :disabled="isDisabled"
								clearable></el-input>
						</el-form-item>
						<el-form-item label="所属机构" prop="companyId">
							<el-select v-model="selectForm.companyId" placeholder="请选择所属机构" filterable clearable
								:disabled="isDisabled">
								<el-option v-for="(item,index) in orderCompanyList" :key="index"
									:label="item.companyName" :value="item.uuid"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="性别" prop="gender">
							<el-select v-model="selectForm.gender" placeholder="请选择性别" filterable clearable
								:disabled="isDisabled">
								<el-option label="男" value="男"></el-option>
								<el-option label="女" value="女"></el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<!-- 第二行 -->
					<el-row>
						<el-form-item label="身份证号码" prop="idcNum">
							<el-input v-model="selectForm.idcNum" autocomplete="off" :disabled="isDisabled" clearable>
							</el-input>
						</el-form-item>
						<el-form-item label="联系电话" prop="phoneNo">
							<el-input v-model="selectForm.phoneNo" autocomplete="off"
                            onkeyup="value=value.replace(/[^\d]/g,'')"
								  maxlength="15" :disabled="isDisabled"
								clearable></el-input>
						</el-form-item>
						<el-form-item label="在岗情况" prop="statusOfOnpost">
							<el-select v-model="selectForm.statusOfOnpost" placeholder="请选择" filterable clearable
								:disabled="isDisabled">
								<el-option label="在职" value="1"></el-option>
								<el-option label="离职" value="0"></el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<!-- 第三行 -->
					<el-row>
						<el-form-item label="紧急联系人" prop="emergencyContact">
							<el-input v-model="selectForm.emergencyContact" autocomplete="off" :disabled="isDisabled"
								clearable></el-input>
						</el-form-item>
						<el-form-item label="紧急联系人电话" prop="emergencyContactPhone">
							<el-input v-model="selectForm.emergencyContactPhone" autocomplete="off"
                            onkeyup="value=value.replace(/[^\d]/g,'')"  :disabled="isDisabled" maxlength="15"
								clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第四行 -->
					<el-row>
						<el-form-item label="紧急联系人联系地址" prop="emergencyContactAddress">
							<el-input v-model="selectForm.emergencyContactAddress" autocomplete="off"
								:disabled="isDisabled" clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第五行 -->
					<el-row>
						<el-form-item label="机动车驾驶证号" prop="licenseId">
							<el-input v-model="selectForm.licenseId" autocomplete="off" :disabled="isDisabled"
								clearable></el-input>
						</el-form-item>
						<el-form-item label="准驾车型" prop="driverType">
							<el-input v-model="selectForm.driverType" autocomplete="off" :disabled="isDisabled"
								clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第六行 -->
					<el-row>
						<el-form-item label="驾驶证有效期限起" prop="driverLicenseOn">
							<el-date-picker v-model="selectForm.driverLicenseOn" type="date" placeholder="选择驾驶证有效期限起始日期"
								:disabled="isDisabled" @change="driverLicenseOnF"></el-date-picker>
						</el-form-item>
						<el-form-item label="驾驶证有效期限止" prop="driverLicenseOff">
							<el-date-picker v-model="selectForm.driverLicenseOff" type="date"
								placeholder="选择驾驶证有效期限截止日期" :disabled="isDisabled" @change="driverLicenseOffF">
							</el-date-picker>
						</el-form-item>
					</el-row>
					<!-- 第七行 -->
					<el-row>
						<el-form-item label="初次领取资格证日" prop="getProofDate">
							<el-date-picker v-model="selectForm.getProofDate" type="date" placeholder="选择初次领取资格证日"
								:disabled="isDisabled" @change="getProofDateF"></el-date-picker>
						</el-form-item>
					</el-row>
					<!-- 第八行 -->
					<el-row>
						<el-form-item label="备注" prop="remark">
							<el-input v-model="selectForm.remark" autocomplete="off" :disabled="isDisabled" clearable>
							</el-input>
						</el-form-item>
					</el-row>
                    <!-- 第九行 -->
					<el-row>
						<el-form-item label="附件" prop="fileList">
							<UploadImg :uploadEdit="isDisabled" v-model="selectForm.fileList" />
						</el-form-item>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="sure()">确 认</el-button>
				</div>
			</el-dialog>
			<!-- 添加弹出框 -->
			<el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
				<span slot="title" class="dialog-Title">添加驾驶员</span>
				<el-form :model="addForm" inline ref="addForm" class="addForm" :rules="addFormRules">
					<!-- 第一行 -->
					<el-row>
						<el-form-item label="驾驶员名称" prop="driverName">
							<el-input v-model="addForm.driverName" autocomplete="off" clearable></el-input>
						</el-form-item>
						<el-form-item label="所属机构" prop="companyId">
							<el-select v-model="addForm.companyId" placeholder="请选择所属机构" filterable clearable>
								<el-option v-for="(item,index) in orderCompanyList" :key="index"
									:label="item.companyName" :value="item.uuid"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="性别" prop="gender">
							<el-select v-model="addForm.gender" placeholder="请选择性别" filterable clearable>
								<el-option label="男" value="男"></el-option>
								<el-option label="女" value="女"></el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<!-- 第二行 -->
					<el-row>
						<el-form-item label="身份证号码" prop="idcNum">
							<el-input v-model="addForm.idcNum" autocomplete="off" clearable></el-input>
						</el-form-item>
						<el-form-item label="联系电话" prop="phoneNo">
							<el-input v-model="addForm.phoneNo" autocomplete="off"
                            onkeyup="value=value.replace(/[^\d]/g,'')"
								  maxlength="15" clearable></el-input>
						</el-form-item>
						<el-form-item label="在岗情况" prop="statusOfOnpost">
							<el-select v-model="addForm.statusOfOnpost" placeholder="请选择" filterable clearable>
								<el-option label="在职" value="1"></el-option>
								<el-option label="离职" value="0"></el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<!-- 第三行 -->
					<el-row>
						<el-form-item label="紧急联系人" prop="emergencyContact">
							<el-input v-model="addForm.emergencyContact" autocomplete="off" clearable></el-input>
						</el-form-item>
						<el-form-item label="紧急联系人电话" prop="emergencyContactPhone">
							<el-input v-model="addForm.emergencyContactPhone" autocomplete="off"
                            onkeyup="value=value.replace(/[^\d]/g,'')"  maxlength="15" clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第四行 -->
					<el-row>
						<el-form-item label="紧急联系人联系地址" prop="emergencyContactAddress">
							<el-input v-model="addForm.emergencyContactAddress" autocomplete="off" clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第五行 -->
					<el-row>
						<el-form-item label="机动车驾驶证号" prop="licenseId">
							<el-input v-model="addForm.licenseId" autocomplete="off" clearable></el-input>
						</el-form-item>
						<el-form-item label="准驾车型" prop="driverType">
							<el-input v-model="addForm.driverType" autocomplete="off" clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第六行 -->
					<el-row>
						<el-form-item label="驾驶证有效期限起" prop="driverLicenseOn">
							<el-date-picker v-model="addForm.driverLicenseOn" type="date" placeholder="选择驾驶证有效期限起始日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="驾驶证有效期限止" prop="driverLicenseOff">
							<el-date-picker v-model="addForm.driverLicenseOff" type="date" placeholder="选择驾驶证有效期限截止日期">
							</el-date-picker>
						</el-form-item>
					</el-row>
					<!-- 第七行 -->
					<el-row>
						<el-form-item label="初次领取资格证日" prop="getProofDate">
							<el-date-picker v-model="addForm.getProofDate" type="date" placeholder="选择初次领取资格证日">
							</el-date-picker>
						</el-form-item>
					</el-row>
					<!-- 第八行 -->
					<el-row>
						<el-form-item label="备注" prop="remark">
							<el-input v-model="addForm.remark" autocomplete="off" clearable></el-input>
						</el-form-item>
					</el-row>
                    <!-- 第九行 -->
					<el-row>
						<el-form-item label="附件" prop="fileList">
							<UploadImg @input="uploadChange" v-model="addForm.fileList" />
						</el-form-item>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="addSure()">确 认</el-button>
				</div>
			</el-dialog>

			<!-- 分成配置弹出框 -->
			<el-dialog :visible.sync="personDialogFormVisible" class="add-dialog" @close="addDialogClose()" width="30%">
				<span slot="title" class="dialog-Title">分成配置</span>
				<el-form :model="personForm" inline ref="addForm" class="addForm">
					<!-- 第一行 -->
					<el-row>
						<el-form-item label="提成类型">
							<el-select v-model="personForm.divideType" placeholder="" @change="checkReceiverChange"
								style="width: 200px;">
								<el-option v-for="item in checkReceiverOptions" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="人员分成比例(%)" prop="personDivideRatio">
							<el-input
                            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
                            v-model="personForm.personDivideRatio" autocomplete="off" style="width: 160px;">
							</el-input>
						</el-form-item>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitPerson">确 认</el-button>
				</div>
			</el-dialog>

		</el-card>
	</div>
</template>

<script>
import _ from 'lodash'
import store from '@/store/index.js'
import UploadImg from '@/components/upload.vue'
function checkPhone (rule, value, callback) { // 手机号验证
  console.log(value, 'valuevaluevalue')
  if (!value) {
    return callback(new Error('手机号不能为空'))
  } else {
    const reg = /^1[3456789]\d{9}$/
    const str = (value + '').replaceAll(' ', '')
    if (reg.test(str)) {
      callback()
    } else {
      return callback(new Error('请输入正确的手机号'))
    }
  }
}
export default {
  name: 'cardministration',
  components: {
    UploadImg
  },
  data () {
    return {
      tabData: [], // 表格数据源
      form: {
        driverName: '',
        phoneNo: ''
      }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      personDialogFormVisible: false,
      personForm: {
        divideType: 2
      },
      checkReceiverOptions: [{ // 提成类型
        label: '比例',
        value: 1
      }, {
        label: '固定值',
        value: 2
      }],
      selectForm: {}, // 选中的表单对象
      addForm: {
        idcNum: '',
        statusOfOnpost: '1'
      }, // 添加表单
      // is: true,
      addFormRules: {
        companyId: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'blur'
        }],
        statusOfOnpost: [{
          required: true,
          message: '请选择在岗情况',
          trigger: 'blur'
        }],
        driverName: [{
          required: true,
          message: '请输入驾驶员名称',
          trigger: 'blur'
        }],
        gender: [{
          required: true,
          message: '请选择性别',
          trigger: 'blur'
        }],
        idcNum: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }],
        phoneNo: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }, { validator: checkPhone, trigger: 'blur' }],
        // statusOfOnpost: [{
        //   required: true,
        //   message: '请选择在岗情况',
        //   trigger: 'blur'
        // }],
        emergencyContactAddress: [{
          required: false,
          message: '请输入紧急联系人联系地址',
          trigger: 'blur'
        }],
        licenseId: [{
          required: false,
          message: '请输入机动车驾驶证号',
          trigger: 'blur'
        }],
        driverType: [{
          required: false,
          message: '请输入准驾车型',
          trigger: 'blur'
        }],
        driverLicenseOn: [{
          required: false,
          message: '请输入驾驶证有效期限起日期',
          trigger: 'blur'
        }],
        driverLicenseOff: [{
          required: false,
          message: '请输入驾驶证有效期限止日期',
          trigger: 'blur'
        }],
        getProofDate: [{
          required: false,
          message: '请输入初次领证日期',
          trigger: 'blur'
        }],
        emergencyContact: [{
          required: false,
          message: '请输入紧急联系人名称',
          trigger: 'blur'
        }],
        emergencyContactPhone: [{
          required: false,
          message: '请输入紧急联系人电话',
          trigger: 'blur'
        }
        // { validator: checkPhone, trigger: 'blur' }
        ]
      }, // 添加表单校验规则
      selectFormRules: {
        companyId: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'blur'
        }],
        statusOfOnpost: [{
          required: true,
          message: '请选择在岗情况',
          trigger: 'blur'
        }],
        driverName: [{
          required: true,
          message: '请输入驾驶员名称',
          trigger: 'blur'
        }],
        gender: [{
          required: true,
          message: '请选择性别',
          trigger: 'blur'
        }],
        idcNum: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }],
        phoneNo: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }, { validator: checkPhone, trigger: 'blur' }],
        // statusOfOnpost: [{
        //   required: true,
        //   message: '请选择在岗情况',
        //   trigger: 'blur'
        // }],
        emergencyContactAddress: [{
          required: false,
          message: '请输入紧急联系人联系地址',
          trigger: 'blur'
        }],
        licenseId: [{
          required: false,
          message: '请输入机动车驾驶证号',
          trigger: 'blur'
        }],
        driverType: [{
          required: false,
          message: '请输入准驾车型',
          trigger: 'blur'
        }],
        driverLicenseOn: [{
          required: false,
          message: '请输入驾驶证有效期限起日期',
          trigger: 'blur'
        }],
        driverLicenseOff: [{
          required: false,
          message: '请输入驾驶证有效期限止日期',
          trigger: 'blur'
        }],
        getProofDate: [{
          required: false,
          message: '请输入初次领证日期',
          trigger: 'blur'
        }],
        emergencyContact: [{
          required: false,
          message: '请输入紧急联系人名称',
          trigger: 'blur'
        }],
        emergencyContactPhone: [{
          required: false,
          message: '请输入紧急联系人电话',
          trigger: 'blur'
        }
        // { validator: checkPhone, trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [{
        label: '在职',
        status: 1
      }, {
        label: '离职',
        status: 0
      }],
      // （0：未婚，1：已婚，2：离异，3：丧偶）
      orderCompanyList: [], // 机构列表
      select: {},
      institutionsOptions: [] // 机构列表

    }
  },
  created () {
    this.getInstitutions()
    this.getAllCar()
    this.getorderCompany()
  },
  computed: {

  },
  methods: {
    /**
     *新增-上传图片
     */
    uploadChange (e) {
      console.log(e, '上传图片')
    },
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    // 获取机构信息
    async getInstitutions () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        // "pageSize": this.paging.pageSize,
        // "pageNo": this.paging.PageNo,
        companyName: '',
        // "status": 1
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取机构列表失败')
      this.institutionsOptions = res.data
      // this.form.companyName = this.institutionsOptions[0].uuid
      // this.getAllCar()
    },
    // 获取驾驶员列表
    async getAllCar () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDriver/getTInfoDriverList2', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        driverName: this.form.driverName,
        statusOfOnpost: this.form.statusOfOnpost,
        phoneNo: this.form.phoneNo,
        corpId: this.form.companyName,
        headers: {
          COMPANY_ID: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'infoDriver'
        }
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取驾驶员列表失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    reset () { // 重置
      for (const key in this.form) {
        this.form[key] = ''
      }
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.search('重置成功')
    },
    // 点击搜索按钮触发
    async search (text = '搜索成功') {
      console.log(this.form)
      // 判断有输入账号
      if (this.form.driverName !== '' || this.form.phoneNo !== '' || this.form.companyName !== '') {
        this.paging.PageNo = 1
        const {
          data: res
        } = await this.$http.post('/pmService/tInfoDriver/getTInfoDriverList2', {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          driverName: this.form.driverName,
          statusOfOnpost: this.form.statusOfOnpost,
          phoneNo: this.form.phoneNo,
          corpId: this.form.companyName,
          headers: {
            COMPANY_ID: store.state.order_company || sessionStorage.getItem('order_company'),
            AUTH_TYPE: 'infoDriver'
          }
        })
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        this.tabData = res.data.records
        this.total = +res.data.total
        // this.$message.success(text)
      } else {
        this.getAllCar()
        // this.$message.success(text)
      }
      // 调接口，进行查询
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除${e.driverName}`, '删除驾驶员', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const {
            data: res
          } = await this.$http.post('/pmService/tInfoDriver/deleteTInfoDriver', JSON.parse(JSON
            .stringify(e)))
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    getProofDateF () {
      this.selectForm.getProofDate = this.getNowFormatDate(this.selectForm.getProofDate)
    },
    driverLicenseOnF () {
      this.selectForm.driverLicenseOn = this.getNowFormatDate(this.selectForm.driverLicenseOn)
    },
    driverLicenseOffF () {
      this.selectForm.driverLicenseOff = this.getNowFormatDate(this.selectForm.driverLicenseOff)
    },
    // 点击详情确定框触发
    async sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) {
        return this.orderDialogFormVisible = false
      } else {
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          // // 进行编辑操作
          this.selectForm.phoneNo = (this.selectForm.phoneNo + '').replaceAll(' ', '')
          const {
            data: res
          } = await this.$http.post('/pmService/tInfoDriver/updateTInfoDriver', JSON.parse(
            JSON.stringify(this.selectForm)))
          // console.log(res);
          // // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // // 成功提示
          this.$message.success('修改成功')
          // // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 添加驾驶员
    addSure () {
      // 预校验
      this.$refs.addForm.validate(async valid => {
        console.log(1)
        if (!valid) return
        console.log(2)
        // 对日期进行格式转换
        console.log(this.addForm, 'this.addForm')
        if (this.addForm.driverLicenseOn) {
          this.addForm.driverLicenseOn = this.getNowFormatDate(this.addForm.driverLicenseOn)
        }
        if (this.addForm.driverLicenseOff) {
          this.addForm.driverLicenseOff = this.getNowFormatDate(this.addForm.driverLicenseOff)
        }
        if (this.addForm.getProofDate) {
          this.addForm.getProofDate = this.getNowFormatDate(this.addForm.getProofDate)
        }
        this.addForm.phoneNo = (this.addForm.phoneNo + '').replaceAll(' ', '')
        this.addForm.createBy = sessionStorage.getItem('userId')

        // 调接口，发送添加客户请求
        const {
          data: res
        } = await this.$http.post('/pmService/tInfoDriver/addTInfoDriver', JSON.parse(JSON
          .stringify(this.addForm)))
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
      this.addForm.fileList = []
    },
    editDialogClose () {
      this.$refs.selectForm.resetFields()
    },
    // 按地区获取机构--调试完成
    async getorderCompany (e) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.orderCompanyList = res.data
    },
    // 时间转换格式    格式YYYY-MM-DD
    getNowFormatDate (v) {
      if (v) {
        const date = v
        const newDateYear = date.getFullYear()
        const newDateMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
        const newDateDay = date.getDate() + '' < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return newDateYear + '-' + newDateMonth + '-' + newDateDay
      } else {
        return ''
      }

      // console.log(v)
      // var date = v
      // var seperator1 = "-";
      // var year = date.getFullYear();
      // var month = date.getMonth() + 1;
      // var strDate = date.getDate();
      // if (month >= 1 && month <= 9) {
      //   month = "0" + month;
      // }
      // if (strDate >= 0 && strDate <= 9) {
      //   strDate = "0" + strDate;
      // }
      // var currentdate = year + seperator1 + month + seperator1 + strDate;
      // return currentdate;
    },
    personClick (row) {
      console.log(row)
      this.personDialogFormVisible = true
      //   this.personForm.personId = row.companyId
      this.personForm.personId = row.uuid
      this.personForm.personType = 9
    },
    checkReceiverChange (v) {
      this.personForm.divideType = v
    },
    async submitPerson () {
      console.log(this.personForm)
      if (!this.personForm.personDivideRatio) return this.$message.error('请输入分成比例')
      const {
        data: res
      } = await this.$http.post('/pmService/personDivide/submitPersonDivide',
        JSON
          .parse(JSON.stringify(this.personForm)))
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error(res.resultDesc)
      this.$message.success('分配成功')
      this.getAllCar()
      this.personDialogFormVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
	.cardministration {
		width: 100%;
		height: 100%;

		//隐藏滚动条
		::-webkit-scrollbar {
			// display: none;
			/* Chrome Safari */
		}

		.father-card {
			.btns {
				margin-bottom: 10px;
			}

			.tab {
				/deep/ .el-table {
					margin-bottom: 20px;

					.el-table__row--striped td {
						background-color: #e4eaec !important;
					}

					.current-row>td {
						background-color: #66b1ff !important;
					}
				}

				.el-pagination {
					margin-bottom: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}
			}

			.add-dialog,
			.order-dialog {
				.dialog-Title {
					color: #0097fe;
					font-size: 20px;
					font-weight: 700;
				}

				/deep/ .el-form {
					.subtitle {
						color: #0097fe;
						font-size: 14px;
						font-weight: 700;
						margin-bottom: 20px;
						padding-left: 6px;
						border-left: 5px solid #0097fe;
					}

					.el-row {
						display: flex;

						.el-form-item {
							flex: 1;
							display: flex;

							.el-form-item__content {
								flex: 1;

								.el-input {
									width: 100%;

									.el-input__inner {
										width: 100%;
									}
								}

								.el-select {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
