<template>
  <div>
    <el-upload :disabled="uploadEdit" class="uploader" :headers="authorToken" :action="UPLOAD_URL" :auto-upload="true"
               :file-list="fileList" list-type="picture-card" :limit="limit?limit:9" :accept="fileType?fileType:'image/*'"
               :on-success="handleGoodsImagesUploadSuccess" :before-upload="handlebeforeUpload" :multiple="true"
               name="multipartFile"
               ref="fileupload" :on-exceed="handleUploadExceed" :on-remove="handleRemove"
               :on-preview="handlePictureCardPreview">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :disabled="uploadEdit" :append-to-body="true" :visible.sync="dialogImgVisible" style="z-index: 3000;text-align: center;"
               :close-on-click-modal="false" :close-on-press-escape="false" custom-class="pub_dialog">
      <img width="80%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<!--
	使用用例
	uploadEdit 是否启用编辑 true false
	fileType可以是
	audio/*表示“任何音频文件
	video/*表示“任何视频文件
	image/*表示“任何图像文件
	还可以以逗号拼接，如image/*,.pdf
	<multi-upload v-model="form.voucherUrlList" :fileType="image/*"></multi-upload>
	import MultiUpload from '@/components/Upload/MultiUpload'
	components: {
		MultiUpload
	},
-->

<script>
var domUrl = process.env.VUE_APP_BASE_API
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 9
    },
    fileType: String,
    uploadEdit: Boolean
  },
  data () {
    return {
      authorToken: {
        Authorization: sessionStorage.getItem('token')
      },
      UPLOAD_URL: '/api/pmService/file/fileUpload', // 上传的图片服务器地址 即上传图片后台接口的路径
      loading: '',
      param: {
        token: ''
      },
      dialogImageUrl: '',
      dialogImgVisible: false,
      disabled: false,
      imgs: [],
      num: 0,
      fileNum: 0
    }
  },
  computed: {
    // ['xxx', 'xxx'] 转换为 [{url: 'xxx'}, {url: 'xxx'}]
    fileList () {
      console.log(this.value, 'this.value')
      return this.value.map((item) => {
        item.url = item.filePath
        return item
      })
    }
  },

  methods: {
    handlePictureCardPreview: function (file) {
      this.dialogImageUrl = file.url
      this.dialogImgVisible = true
    },
    handleRemove: function (file, fileList) {
      console.log(fileList, 'fileList')
      // fileList 为删除后的文件列表
      const value = fileList.map(v => v)
      this.$emit('input', value)
    },
    handleGoodsImagesUploadSuccess (response, file, fileList) {
      console.log(response)
      console.log(fileList)

      if (response.statusCode == 200) {
        const { filePath: imageUrl, fileName } = response.data
        const obj = {
          filePath: imageUrl,
          fileName: fileName
        }
        this.imgs.push(obj)
        console.log(this.imgs, 'this.imgsthis.imgs')
        this.num++
        if (this.num == this.fileNum) {
          this.num = 0
          this.fileNum = 0
          // 这里如果 this.value.push(imageUrl) 这么写，vue会报出警告，大概意思是value作为props不应该在子组件中被修改
          // 应该根据 value 得到新的值，而不能修改它，this.value.concat(imageUrl)也是可以的，concat方法返回新的数组
          // this.$emit('input', [...this.value, imageUrl])
          this.$emit('input', this.value.concat(this.imgs))
          this.imgs = []
        }
      } else {
        this.$message.error(file.name + '上传失败!')
      }
    },
    handlebeforeUpload (file) {
      // 这里做可以做文件校验操作
      const isImg = /^image\/\w+$/i.test(file.type)
      const isLt2M = file.size / 1024 / 1024 < 10

      if (!isImg && this.fileType == 'image/*') {
        this.$message.error('只能上传 JPG、PNG、GIF 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
        return false
      }

      this.fileNum++
      console.log(this.fileNum)
    },
    handleUploadExceed () {
      this.$message.error(`最多上传${this.limit}张图片`)
    }
  }
}
</script>
<style>
/*去除upload组件过渡效果*/
.el-upload-list__item {
  transition: none !important;
}
</style>
<style scoped lang="less">

// /deep/.el-upload--picture-card {
//   display: none;
// }
.uploader {
    /deep/img{
        object-fit: cover;
    }
}
</style>
